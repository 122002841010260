import styled from 'styled-components';

import { ReactComponent as DotImg } from 'assets/icons/dots.svg';
import profileIcon from 'assets/images/Group 1-a.png';
// import profileIcon from "assets/images/profile-icon.png";
import notificationIcon from 'assets/images/notification-icon.svg';
// import navAirtimeImg from "assets/images/nav-airtime.png";
// import navElectricityImg from "assets/images/nav-electricity.png";
// import navCableImg from "assets/images/nav-cable.png";
// import success from "assets/icons/Rectangle 6.png";

import { Link } from 'react-router-dom';
import {
  ACCOUNT,
  DASHBOARD,
  NOTIFICATIONS,
  TRANSACTIONS,
} from 'pages/pagesPath';
import { formatNumber } from '../helpers/formatNumber';
import Button from 'components/reusables/Button';
import RNavItem from 'components/RNavItem';
import { Burger } from './UnAuthNavbar';
import DoughnutChart from './charts/DoughnutChart';
import { userData } from 'utility/helper';
import { useTransactionHistoryQuery } from 'redux/services/transaction-service';
import { useNavTransactionHistoryQuery } from 'redux/services/transaction-service';
import { useTotalSpentQuery } from 'redux/services/transaction-service';
import moment from 'moment';
import { Skeleton } from 'antd';
import { useState, useEffect } from 'react';

const RightNav = props => {
  const { open, setOpen } = props;
  const { data }: any = useTransactionHistoryQuery(userData()?.phoneNumber);
  const { data: data1, isLoading }: any = useNavTransactionHistoryQuery(
    userData()?.userID
  );

  const [totalDate, setTotalDate] = useState(
    moment().subtract(3, 'months').format('MM-DD-YYYY')
  );
  const { data: data2, refetch } = useTotalSpentQuery({
    userId: userData()?.userID,
    date: totalDate,
  });

  const handleDurationChange = e => {
    const val = e.target.value;
    if (val === 'all') {
      setTotalDate('');
    } else {
      setTotalDate(
        moment().subtract(parseInt(val), 'months').format('MM-DD-YYYY')
      );
    }
  };

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalDate]);

  // useEffect(() => {
  //   setTotalDate(moment().subtract(12, "months").format("MM-DD-YYYY"));
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [totalAmtLoading]);

  return (
    <RightNav.Wrapper>
      <div className='nav-header'>
        <Burger open={open} setOpen={setOpen} />
        <div className='nav-sub'>
          <div>
            <p className='ub'> uBalance</p>
            <h2 style={{ fontFamily: 'Mollen Personal Use-Bold' }}>
              <b style={{ color: '#0F227A' }}>
                &#8358;&nbsp;
                {formatNumber(Number(data?.profile?.uBalance)) || '0.00'}
              </b>{' '}
              <br />
            </h2>
          </div>
          <Link to={NOTIFICATIONS} className='mt-3 ml-4'>
            <img src={notificationIcon} alt='notification icon' />
          </Link>
          <Link to={ACCOUNT} className='mt-3 ml-4'>
            <img src={profileIcon} alt='profile icon' />
          </Link>
        </div>
      </div>
      <div className='header'>
        <p>
          Total Spending
          <DotImg className='ml-2' />
        </p>

        {data2 && (
          <h6 style={{ fontFamily: 'Mollen Personal Use-Bold' }}>
            <b>
              &#8358;&nbsp;
              {formatNumber(Number(data2)) || '0.00'}
            </b>
          </h6>
        )}
        <select
          name='duration'
          id='duration'
          className='sDuration1'
          onChange={handleDurationChange}
          defaultValue={'3'}
        >
          <option value='3'>3 months</option>
          <option value='6'>6 months</option>
          <option value='9'>9 months</option>
          <option value='12'>12 months</option>
          <option value='all'>all</option>
        </select>
      </div>
      <div className='metrics'>
        <div className='mini-chart'>
          <DoughnutChart />
        </div>
        <Link to={DASHBOARD}>
          <Button
            label='View'
            styleClass='mt-3'
            bg='#f4fff7'
            border='1px solid #03B66B'
            text='#03B66B'
          />
        </Link>

        <div className='mt-4'>Most Recent Transactions</div>
        {isLoading && <Skeleton />}

        {data1?.slice(0, 3).map((item, id) => (
          <RNavItem
            key={id}
            name={`${item.type} purchase`}
            // img={success}
            status={item.status}
            // no="1"
            date={item.createdAt}
            amount={`₦ ${formatNumber(Number(item.amount)) || '0.00'}`}
            // currentModal={1}
            // func={setCurrentModal}
          />
        ))}
        <Link
          to={TRANSACTIONS}
          style={{ textDecoration: 'none' }}
          className='see-more'
        >
          See More
        </Link>
      </div>
    </RightNav.Wrapper>
  );
};

RightNav.Wrapper = styled.div`
  // background: #f4fff7;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding-right: 1.5rem;
  padding-left: 1.5rem;
  // z-index: 20;
  .nav-header {
    button {
      display: none;
    }
    display: flex;
    flex-direction: row;
    justify-content: end;
    // // justify-items: center;
    align-items: center;
    .nav-sub {
      display: flex;
      /* flex-direction: row; */
      /* justify-content: end; */
      // justify-items: center;
      /* align-items: end; */
      margin-top: 1rem;
      .ub {
        margin: 0rem;
        font-size: 0.9rem;
        font-weight: bold;
      }
      p {
        /* margin-top: 2rem; */
        color: #0f227a;
        // font-size: 13px;
        font-size: 18px;
      }
    }
  }
  .header {
    color: #2c2c4b;
    font-size: 12px;
    h2 {
      color: #3e3d75;
      // font-size: 36px;
      // line-height: 43px;
    }
    span {
      color: #00000080;
      // font-size: 10px;
    }
  }
  .mini-chart {
    margin-top: 1rem;
    width: inherit;
    height: auto;
    border: transparent;
    // border: solid;
    box-sizing: border-box;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    // padding: 50px;
    padding: 10px;
    background: #ffffff;
  }
  .container {
  }
  .see-more {
    color: #03b66b;
    font-size: 12px;
    line-height: 14px;
    text-align: end;
  }
  .sDuration1 {
    // display: inline;
    // border: solid 0.5px #0f227a;
    // border-radius: 10px;
    border: none;
    background: #f4fff7;
    font-size: 12px;
    color: #363636;
    margin-inline-start: auto;
    margin-bottom: 0.5rem;
    margin-top: 2rem;
    /* padding: 0.5rem 0; */
  }

  h6 {
    color: #0f227a;
  }

  @media (max-width: 1200px) {
    .nav-header {
      justify-content: space-between;
      button {
        display: flex;
      }
    }
    .metrics {
      display: none;
    }
  }
`;
export default RightNav;
