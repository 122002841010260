import styled from "@emotion/styled";
import { protectedRoutes } from "./routes";
import LeftNav from "components/LeftNav";
import RightNav from "components/RightNav";
import { useEffect, useState } from "react";
import { convertRoutesToComponents } from "helpers/pagesParser";
import { Route, Switch } from "react-router";
import VerifyNumber from "./VerifyNumber";
import VerifyPayment from "./VerifyPayment";
import { userData } from "utility/helper";
import CreatePinPage from "./CreatePinPage";
import { CREATEPIN } from "pages/pagesPath";

interface toggleNavType {
  show: boolean;
}
const AuthPages = () => {
  const [show, setShow] = useState(false);
  if (!localStorage.getItem("token")) {
    window.location.pathname = "/";
  }

  useEffect(() => {
    if (!userData()?.verified && window.location.pathname !== "/user/verify") {
      window.location.pathname = "/user/verify";
    }

    if (
      userData()?.verified &&
      !userData()?.hasPin &&
      window.location.pathname !== CREATEPIN
    ) {
      window.location.pathname = CREATEPIN;
    }
  }, []);
  return (
    <>
      <Switch>
        <Route
          path="/user/verify-payment"
          exact={true}
          component={VerifyPayment}
        />
        <Route path="/user/verify" exact={true} component={VerifyNumber} />
        <Route path="/user/create-pin" exact={true} component={CreatePinPage} />
        <Route path="/user">
          <AuthPagesWrapper show={show}>
            <div className="left" onClick={() => setShow(false)}>
              <LeftNav setOpen={setShow} />
            </div>

            <div className="main-child" onClick={() => setShow(false)}>
              {convertRoutesToComponents(protectedRoutes)}
            </div>
            <div className="right">
              <RightNav open={show} setOpen={setShow} />
            </div>
          </AuthPagesWrapper>
        </Route>
      </Switch>
    </>
  );
};
const AuthPagesWrapper = styled.div<toggleNavType>`
  display: flex;
  width: 98vw;
  justify-content: space-between;
  background: #ffffff;
  SideNav {
    width: 100px;
  }
  .left {
    width: 17%;
    border: 1px solid #f2f2f2;
  }
  .right {
    width: 23%;
    background: #f4fff7;
  }
  .main-child {
    width: 60%;
    min-height: 100vh;
  }

  @media screen and (max-width: 1200px) {
    .left {
      position: fixed;
      top: 0;
      // left: ${({ show }) => (show ? "0" : "-100vw")};
      left: 0;
      width: 250px;
      border: 1px solid #f2f2f2;
      z-index: 20;
      background: #ffffff;
      min-height: 100vh;
      display: none;
    }
    .right {
      width: 30%;
      background: #f4fff7;
    }
    .main-child {
      width: 70%;
      min-height: 100vh;
    }
  }
  @media screen and (max-width: 920px) {
    flex-direction: column-reverse;
    .left {
      position: fixed;
      width: 100vw;
      border: 1px solid #f2f2f2;
      background: rgba(0, 0, 0, 0.75);
      height: 100vh;
      transition: all 0.3s linear;
      display: block;
      left: ${({ show }) => (show ? "0" : "-100vw")};
      border: none;
      overflow-y: scroll;
    }
    .right {
      width: 100%;
      background: #f4fff7;
    }
    .main-child {
      width: 100%;
      min-height: 100vh;
    }
  }
`;

export default AuthPages;
